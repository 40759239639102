import { createStore } from 'vuex'
import {reactive} from "vue";

let defaultSetting ={
    count: 0,
    userName:'',
    userDBinfo:reactive({}),
    asrRe:'',
    aiRewriteRe:'',
    isShowLoadingGif:true,
    curEditingNoteId:'',
    loadingEditContent:null,
    curResultTitle:'',
    funFetchNote:null,
    debugFun:null,
    arrSelectedNote: new Set,
    mapNotes: new Map,
    serverStaticSetting:{price:[]},
    settings:reactive({funcSelected:"1",
        rewriteLen:80,rewriteFlexibility:1,styleID:1,styleTxt:'',customStyleTxt:'',lang:"cn",inputLan:"both"})
}

//创建一个新的store实例
const store = createStore({
    //存储数据
    state() {
        return defaultSetting;
    },
    //修改数据的方法
    mutations: {
        increment(state) {
            state.count++
        }
        ,
        resetUserInfo(state){
            state = defaultSetting;
            state.mapNotes.clear();
        }
    }
})

export default store;
