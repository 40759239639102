import store from "../utility/store";
import {watch} from "vue";

function  fixSetting(){
    if (store.state.settings.funcSelected==null || store.state.settings.funcSelected.length == 0){
        store.state.settings.funcSelected = "1";
    }
    if(store.state.settings.styleID == null || store.state.settings.styleID == 0){
        store.state.settings.styleID = 1;
    }
}

watch(() => store.state.settings,async (newVal, oldVal) => {
    //console.log("检测到值变化 store.state.settings",({ newVal, oldVal }))
    fixSetting();
})

watch(() => store.state.settings.styleID , (newVal, oldVal) => {
    console.log("检测到值变化 store.state.settings.styleID",({ newVal, oldVal }))
    fixSetting();
})
