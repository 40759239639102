import {createRouter, createWebHistory} from 'vue-router'

const routerHistory = createWebHistory()

import index from './views/index.vue'
import login from './views/login.vue'
import notfound from './views/notfound.vue'
// 定义路由
const routes = [
    {
        path: import.meta.env.VITE_ROUTER_PREFIX+'/',
        name: 'index',
        component: index
    },
    {
        path: import.meta.env.VITE_ROUTER_PREFIX+'/login',
        name: 'login',
        component: login
    },
    {
        path: '/:pathMatch(.*)*',
        // name: 'notfound',
        component: notfound
    },
]

// 创建路由器
const router = createRouter({
    history: routerHistory,
    routes: routes
})
//
// router.beforeEach((to, from, next) => {
//     new Promise((resolve, reject) => {
//         // ...other codes
//     }).then(res => {
//         if (!to.matched.length) {
//             window.alert("not match")
//             window.location = '/p404'
//             return
//         }
//         if (res) {
//             next(res)
//         } else {
//             next()
//         }
//     }).catch(err => {
//         new Error(err)
//         next(false)
//     })
// })

export default router;
