import store from "./store"
import BrowserLogger from '@arms/js-sdk'

export function getLocalString(te,t,key){
    return te(key)? t(key):key;
}
export function getStackTraceString() {
    try {
        let err =  new Error("调用堆栈");
        const __bl = BrowserLogger.singleton(
            {
                pid:"feamcqah85@4922c2778dbe531",
                appType:"web",
                imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
                sendResource:true,
                enableLinkTrace:true,
                behavior:true
            })
        console.log("report arms-retcode",err)
        __bl.error(err);
        throw err;
    } catch (error) {
        return error.stack;
    }
}
export  function checkMediaFile(fileName){
    let suffix = ''; // 后缀获取
    if (fileName) {
        const flieArr = fileName.split('.'); // 根据.分割数组
        suffix = flieArr[flieArr.length - 1]; // 取最后一个
    }
    if (!suffix) return false; // fileName无后缀返回false
    suffix = suffix.toLocaleLowerCase(); // 将后缀所有字母改为小写方便操作

    console.log("suffix",suffix)
    const radioList = ['mp3', 'wav', 'wmv','m4a'];
    let result = radioList.find(item => item === suffix);
    if (result) return true;

    return  false;
}

// 获取浏览器默认语言
export const getBrowserLang = function() {
    let browserLang = navigator.language
        ? navigator.language
        : navigator.browserLanguage;
    let defaultBrowserLang = "";
    if (
        browserLang.toLowerCase() === "us" ||
        browserLang.toLowerCase() === "en" ||
        browserLang.toLowerCase() === "en_us"
    ) {
        defaultBrowserLang = "en_US";
    } else {
        defaultBrowserLang = "zh_CN";
    }
    return defaultBrowserLang;
};

export  function getUserLocalIsCN(){
    let m = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase().indexOf("shanghai")
    let n = navigator.language.toLowerCase().indexOf('cn');

    console.log("m,n",m,n)
    if (m >=0 || n >=0)
        return true;

    return  false;
}

export  async function fetchNote(){
    if (store.state.userName.length ==0)
        return;

    console.log("fetchNote loginedInfo:",store.state)
    let tmp = await getNoteList({userName:store.state.userName})

    onUserData(tmp.data);
}

export function checkLogin(){
    if(store.state.userName.length ==0){
        $('#login').modal('show')
        return  false;
    }
    else return true;
}
export  function onUserData(data){
    if (data.settings){
        store.state.settings = data.settings;
    }

    if(data.userDBinfo){
        store.state.userDBinfo = data.userDBinfo;
    }

    if(data.serverStaticSetting){
        store.state.serverStaticSetting.price.length = 0;
        store.state.serverStaticSetting.price.push(...data.serverStaticSetting.price)
        //store.state.serverStaticSetting.price = data.serverStaticSetting.price;
        console.log(" data.serverStaticSetting.price", data.serverStaticSetting.price)
    }

    console.log("store.state.serverStaticSetting", store.state.serverStaticSetting.price)

    store.state.mapNotes.clear();

    if (data.notes && data.notes.length > 0)
        data.notes.forEach(function (item, index) {
            item.selected = false;
            store.state.mapNotes.set(item.id, item);
        })
}
export function  reviseTitle(zipTxt) {
    let maxTitleLen = zipTxt.length>25?25:zipTxt.length;
    const reg = RegExp(/,|\.|，|。|\n|;|；|\s/);

    let tmp = zipTxt.substr(0,maxTitleLen);
    tmp = tmp.split('').reverse().join('');

    let nPos = tmp.search(reg);
    if (nPos != -1){
        tmp = tmp.split('').reverse().join('');
        tmp = tmp.substring(0,tmp.length-(nPos+1))
    }
    else{
        tmp =  zipTxt.substr(0,maxTitleLen);///如果不重新赋值会出现字符串时翻转的,此处应翻转或直接截取
    }
    return tmp;
}
export function formattedDate(timeunix) {
    const time = new Date(timeunix * 1000);
    const y = time.getFullYear();
    const m = time.getMonth() + 1;//getMonth获取的月份为（0--11），实际月份需要+1得出
    const d = time.getDate();
    const h = time.getHours();
    const mm = time.getMinutes();
    const s = time.getSeconds();
    const realtime = y + '-' + addZero(m) + '-' + addZero(d) + ' ' + addZero(h) + ':' + addZero(mm) + ':' + addZero(s);
    return realtime;
}

export function repeact(func, count, time) {
    return function (word) {
        let interv = function () {
            count--
            let timer = setTimeout(interv, time);
            if (func(word) == true || (timer && count <= 0)) {
                clearTimeout(timer);
                timer = null;
            }
        }
        setTimeout(interv, time)

        //interval(func, time)
    }
}

/**
 * 添加0值
 */
function addZero(m) {
    return m < 10 ? '0' + m : m;
}

import {getNoteList, getOssSTSToken, StarASRByOSS} from "../api/api";
import {watch} from "vue";

export async function getTokenFromS() {
    let re;
    await getOssSTSToken().then(response => {
        console.log("wyc:" + JSON.stringify(response.data));
        re = response.data;
    });

    return re;
}

export async function starASR(para) {
    let re;
    try {
        await StarASRByOSS(para).then(response => {
            console.log("StarASRByOSS",response);
            re = response;
        });
    } catch (e) {
        console.log("asr error")
    }
    return re;
}

