import api from "./apiCore.js";
// 下面是简写的形式
// getXXX 自定义的接口名字
export const getXXX = (params) => api.get(`/apigb/v1/component`, { params });
export const postXXX = (params) =>
  api.post(`/apigb/v1/component/update-info`, params);


///测试接口,这个地方微信会回调
export const  ApiCheckPayResult = async (params) =>{
    return api({
        url: "/wepay", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
    });
}

export const  ApiCheckPayOder = async (params) =>{
    return api({
        url: "/check_wepay_order", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
        params: {}, // 请求参数dd
    });
}


export const  ApiGetPayUrl = async (params) =>{
    return api({
        url: "/get_wepay_url", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
        params: {}, // 请求参数dd
    });
}
export const  ApiDeleteNote = async (params) =>{
    return api({
        url: "/deleteNote", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
        params: {}, // 请求参数dd
    });
}

export const  ApiSaveLog = async (params) =>{
    try {
        return api({
            url: "/saveLog", // 请求地址
            method: "post", // 请求方式
            data: params, // 请求参数dd
        });
    }
    catch (e) {
        console.log("error while savelog")
    }
}


export const  ApiSaveUserSetting = async (params) =>{
    return api({
        url: "/saveUserInfo", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
    });
}

export const  ApiSaveNote = async (params) =>{
    return api({
        url: "/saveNote", // 请求地址
        method: "post", // 请求方式
        data: params
    });
}
export const  ApiAiRewrite2 = async (params) =>{
    return api({
        url: "/aiRewrite2", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
        timeout: 60000 // 设置此请求的超时时间为 60 秒
    });
}

export const getNoteList = (params) =>{
    return api({
        url: "/GetNoteList", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
        params: params, // 请求参数dd
    });
}

export  const regUsr = async (params) =>{
    return api({
        url: "/reg", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
    });
}

export  const login = async (params) =>{
    console.log("api.login")
    return api({
        url: "/login", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数dd
    });
}
export const getToken = (params) =>{
    return api({
        url: "/getToken", // 请求地址
        method: "post", // 请求方式
        params: params, // 请求参数
    });
}

export const getOssSTSToken = (params) =>{
    return api({
        url: "/getOssSTSToken", // 请求地址
        method: "post", // 请求方式
        params: params, // 请求参数
    });
}

export const  StarASRByOSS = async (params) =>{
    return api({
        url: "/StarASRByOSS", // 请求地址
        method: "post", // 请求方式
        data: params, // 请求参数
    });
}

