<template>
<!--      <div>-->
<!--          <index />-->
<!--      </div>-->
<!--  cent svr default page-->
<!--  <router-view></router-view>-->
  <div ><a href="./login/">登录 </a></div>
  <div v-show="data.mode=='dev'">
    <a href="http://127.0.0.1:8088/audionote/">1 录音笔记 </a>
  </div>
  <div v-show="data.mode=='production'">
    <a href="https://aaaii.cn/audionote/">1 录音笔记 </a>
  </div>
  <div><a href="./chatgpt/">2 chatgpt </a></div>
</template>

<script setup>
import {getUserLocalIsCN} from "./utility/common";
getUserLocalIsCN();
import {reactive} from "vue";
///todo  import.meta.env.MODE 不能在v-show表达式里面
//console.log("import.meta.env.VITE_BASE_API",import.meta.env.MODE=='dev')
// console.log("import.meta.env.MODE",import.meta.env)
let data  = reactive({mode:""})
data.mode = import.meta.env.MODE;
</script>
