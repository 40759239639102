
// 获取assets静态资源
export const getAssetsFile = (url: string) => {
    let a = new URL(`../assets/images/${url}`, import.meta.url).href
    return a;
}

let a = 1;
export  function testES2015(){
    // 获取assets静态资源
// Babel Input: ES2015 arrow function
    [1, 2, 3].map(n => n + 1);

// Babel Output: ES5 equivalent
    [1, 2, 3].map(function(n) {
        return n + 1;
    });
    console.log("testES2015");
    console.log( [1, 2, 3].map(function(n) {
        return n + 1;
    }));
}
