import {
    allowMultipleToast,
    resetToastDefaultOptions,
    setToastDefaultOptions,
    showFailToast,
    showSuccessToast, showToast
} from "vant";

import 'vant/lib/index.css';
import {ApiSaveLog} from "../api/api";
import store from "../utility/store";
import BrowserLogger from "@arms/js-sdk";

setToastDefaultOptions({ duration: 3000 , position: 'top',closeOnClick:true});

//setToastDefaultOptions('loading', { forbidClick: false });
//resetToastDefaultOptions();
//resetToastDefaultOptions('loading');
export function  showSucc(txt){
    setToastDefaultOptions({ duration: 3000 , position: 'top',closeOnClick:true});
    showSuccessToast(txt);
    // const toast2 = showToast(
    //     {
    //         message: txt,
    //         position: 'top',
    //     });
}

export function  showFail(txt,exceptionMsg){
    setToastDefaultOptions({ duration: 5000 , position: 'top',closeOnClick:true});
    let errMsg ;
    if(txt instanceof Error){
        errMsg = txt.name + ": " + txt.message

        const __bl = BrowserLogger.singleton(
            {
                pid:"feamcqah85@4922c2778dbe531",
                appType:"web",
                imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
                sendResource:true,
                enableLinkTrace:true,
                behavior:true
            })
        __bl.error(txt);
        console.log("report arms-retcode",err)
    } else if(typeof(txt)=='string'){
        showFailToast(txt);
    }
    else{
        showFailToast(exceptionMsg);/// __bl.error上报在getStackTraceString中已经处理
    }

    if(exceptionMsg == null)
        return;

    let re;
    try {
        console.log(exceptionMsg);
        re = ApiSaveLog({exceptionMsg});/// todo 这里如果svr连接不上,会循环报错
    }
    catch (e) {
        store.state.debugFun(re+"\r\n"+exceptionMsg)
    }
}
