import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        login: {
            title: 'login',
            hint: 'Computer Science And Software Engineering',
            guessYouLike: 'Guess You Like',
            login_succ:'login successful',
            err_mima_err:"password wrong",
            wrong_input:"wrong input",
        },
        register:{
            title:"register",
            err_username:"user name error",
            err_mima_buyiyang:"password not match",
            err_mima_kong:"password cannot be empty",
            svr_exception:"server exception",
            user_exsit:"user already exsit",
            reg_succ:"reg sucessful"
        },
        note:{
            save_succ:"save success",
            del_succ:"delete success",
            save_note_find:"not found while saving"
        },
        common:{
            error:"error,if again, contact administrator",
            upload_err:"upload failed",
            user_not_exsit:'user not exsit',
            storage_err : 'write db exception ,contact administrator',
            setting_save_success:"save success",
            note_save_success:"note save success",
            ai_fun_rewrite:'AI rewrite',
            ai_fun_translate:'AI translator',
            appName:'audio AI note',
            ifyoulike:'Upgrade Prime to support ',
            slogn:'Capture Life\'s Inspirations Anytime, Anywhere!'
        }
    },
    cn: {
        login: {
            title:"登录",
            login_succ:"登录成功",
            user_not_exsit:'用户不存在',
            err_mima_err:"密码错误",
            wrong_input:"字段不能为空",
        },
        register:{
            title:"注册",
            err_mima_buyiyang:"两次输入密码不一样",
            err_mima_kong:"密码不能为空",
        },
        note:{
            save_succ:"保存成功",
            del_succ:"删除成功",
            save_note_find:"未找到要保存的笔记"
        },
        common:{
            error:"错误,请重试或联系管理员",
            user_not_exsit:'用户不存在',
            note_save_success:'保存成功',
            ai_fun_rewrite:'AI重写',
            ai_fun_translate:'AI翻译',
            appName:'AI录音笔记',
            ifyoulike:'升级到专业版支持',
            slogn:'随时随地,记录生活灵感!'
        }
    }
}

const i18n = createI18n({
    legacy: false, // VUE3 组合式API
    locale: 'cn', // 默认cn语言环境
    fallbackLocale: 'en',  //备用语言环境
    messages
})

export default i18n
