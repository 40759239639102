<template>
  <div>login</div>

  <!-- 登录窗口 -->
  <div id="login" >
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 21px;">
        <div class="modal-body">
          <button class="close" data-dismiss="modal">
            <span>×</span>
          </button>
        </div>
        <div class="modal-title">
          <h1 class="text-center">{{ t("login.title") }}</h1>
        </div>
        <div class="modal-body">
          <form class="form-group" @submit="onLogin">
            <div class="form-group">
              <input class="form-control"  v-model="loginInfo.email" type="text" placeholder="user name">
            </div>
            <div class="form-group">
              <input class="form-control" v-model="loginInfo.pass1"  type="password" placeholder="password">
            </div>
            <div class="text-right">
              <button class="btn btn-primary" style="margin: 10px" >login</button>
              <button class="btn btn-danger" data-dismiss="modal">cancel</button>
            </div>
            <a href="" data-toggle="modal" data-dismiss="modal" data-target="#register">no account,register?</a>
          </form>
        </div>
      </div>
    </div>
    <div id="login_container"></div>
  </div>
</template>

<script setup>

import {onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {login, regUsr} from "../api/api";
import { getCurrentInstance } from 'vue';
import {useStore} from "vuex";
import {fetchNote, getLocalString, getStackTraceString} from "../utility/common";
import {useI18n} from "vue-i18n";
import {showSucc,showFail} from "../components/toast";
const { t, te,locale } = useI18n()

let store = useStore();

///setting要有默认参数,如果没有,sever端默认解析的时候会报异常
var regInfo =reactive({email:'',pass1:'',pass2:"",settings:store.state.settings})

var loginInfo =reactive({email:'',pass1:''})

const instance = getCurrentInstance();

let logindStyles = ref('')///这样不行,index界面的login 元素访问不了当前的这个变量绑定的css样式表 .logined

async function  onLogin() {
  //window.alert("onLogin",import.meta.env.VITE_BASE_API,import.meta.env.VITE_AUDIONOTE_SVR)
  window.location.href = import.meta.env.VITE_AUDIONOTE_SVR;
  event.preventDefault(); // 阻止默认提交行为
}
</script>

<style scoped>

</style>
