import axios from "axios";
import {showFail} from "../components/toast";
import {getStackTraceString} from "../utility/common";

let _api = function () {
    try{
        let t=  axios.create({
            //baseURL: "http://fcaudionotesvr-audionotesvr-ppcbwekcgu.cn-hongkong-vpc.fcapp.run", // 所有请求的公共地址部分
            //baseURL: "https://www.ai168.top/", // 所有请求的公共地址部分
            //baseURL: "http://192.168.1.113:9000/", // 所有请求的公共地址部分
            baseURL: import.meta.env.VITE_BASE_API, // 所有请求的公共地址部分
            timeout: 6000, // 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
        })
        return t;
    }catch (e) {
        showFail(e,e.stack);
    }
}

const api = _api();

axios.defaults.withCredentials = true;

// 请求相关处理 请求拦截 在请求拦截中可以补充请求相关的配置
// interceptors axios的拦截器对象
api.interceptors.request.use(
    (config) => {
        // config 请求的所有信息
        // console.log(config);

        let token = $cookie.get('token')
        let userName = $cookie.get('userName')
        //console.log("getToken:",token);

        if (config.method === 'post') {
            //config.headers['Authorization'] = token;

            //console.log("config.data:",config.data)
            config.data={
                Authorization:token,
                userName,
                ...config.data
            }
        }

        //console.log("config:",config)

        return config; // 将配置完成的config对象返回出去 如果不返回 请求讲不会进行
    },
    (err) => {
        // 请求发生错误时的相关处理 抛出错误
        Promise.reject(err);
        showFail(err,getStackTraceString());
    }
);

api.interceptors.response.use(
    (res) => {
        // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
        // res 是所有相应的信息
        console.log(res);
        return Promise.resolve(res);
    },
    (err) => {
        if(err.response.status ){
            console.log("err.response.status",err.response.status)
        }
        // 服务器响应发生错误时的处理
        Promise.reject(err);
        //showFail(err,getStackTraceString()); 此处如果是断网,继续上传日志,又会异常到这里,会形成死循环
    }
);

export default api;
